<template>
  <div class="notifications-list">
      <progress-indicator v-if="data.length === 0">
        Loading...
      </progress-indicator>
      <SimpleInfoTable
        v-else
        :table-id="'notification-list-table'"
        :items="data"
        :show-pagination="false"
        :columns="{
          'app': {header: 'App Name', orientation: 'left'},
          'periodicity': {header: 'Periodicity', orientation: 'left'},
          'countries': {header: 'Countries', orientation: 'left'},
          'content': {header: 'Content', orientation: 'left'},
          'delete': {header: ''}
        }"
      >
        <template v-slot:items-delete="slotProps">
          <div class="actions-column" v-if="slotProps.item.notification.periodicity !== undefined">
            <table-actions-tooltip class="action-button delete-button"
                                   @click="clear(slotProps.item.app.id)">
              <template v-slot:tooltip-icon>
                <svg-icon icon="close-alt" />
              </template>
              <template v-slot:tooltip-text>
                Clear
              </template>
            </table-actions-tooltip>
            <table-actions-tooltip class="action-button adjust-action">
              <template v-slot:tooltip-icon>
                <router-link :to="'/notification-settings/' + slotProps.item.app.id">
                  <svg-icon icon="setting" />
                </router-link>
              </template>
              <template v-slot:tooltip-text>
                Adjust
              </template>
            </table-actions-tooltip>
          </div>
        </template>
        <template v-slot:items-app="slotProps">
          <app-title :title="slotProps.item.app.title"
                     :logo="slotProps.item.app.logo"
                     :store-link="slotProps.item.app.store_link"
                     :store="slotProps.item.app.store"
          />
        </template>
        <template v-slot:items-periodicity="slotProps">
          <div class="periodicity"
               v-if="slotProps.item.notification.periodicity !== undefined"
               :class="slotProps.item.notification.periodicity">
            {{ slotProps.item.notification.periodicity }}
          </div>
          <router-link v-else
                       :to="'/notification-settings/' + slotProps.item.app.id"
                       class="adjust-link">
            <svg-icon icon="plus" />
            Assign
          </router-link>
        </template>
        <template v-slot:items-content="slotProps">
          <div class="content-block" v-if="slotProps.item.notification?.types?.length > 0">
            <div class="count">
              ({{ Object.keys(slotProps.item.notification.types).length }})
            </div>
            <div v-for="type in slotProps.item.notification.types"
                 class="report-name"
                 :class="type.type"
            >
              {{ getTypeTitle(type.type) }}
            </div>
          </div>
        </template>
        <template v-slot:items-countries="slotProps">
          <div class="countries-dropdown-wrap"
               v-if="slotProps.item.notification?.countries?.length > 0">
            <template v-if="slotProps.item.notification?.countries?.length < 3">
              <div v-for="country in slotProps.item.notification?.countries"
                   class="country-title">
                <dynamic-image classes="country-flag"
                               :width="14"
                               :height="10"
                               v-if="country.code"
                               :country-code="country.code"/>
                <div>
                  {{ country.title }}
                </div>
              </div>
            </template>
            <custom-dropdown v-else :min-width="'180px'">
              <template v-slot:header>
                <div class="country-title">
                  <dynamic-image classes="country-flag"
                                 :width="14"
                                 :height="10"
                                 v-if="slotProps.item.notification?.countries[0].code"
                                 :country-code="slotProps.item.notification?.countries[0].code"/>
                  <div>
                    {{ slotProps.item.notification?.countries[0].title }}
                    (+{{ slotProps.item.notification?.countries?.length - 1 }})
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="select-options countries-dropdown">
                  <div v-for="country in slotProps.item.notification?.countries"
                       class="option country-title">
                    <dynamic-image classes="country-flag"
                                   :width="14"
                                   :height="10"
                                   v-if="country.code"
                                   :country-code="country.code"/>
                    <div>
                      {{ country.title }}
                    </div>
                  </div>
                </div>
              </template>
            </custom-dropdown>
          </div>
        </template>
      </SimpleInfoTable>
    </div>
</template>

<script>
import TheNewHeader from "@/components/TheNewHeader/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import DynamicImage from "@/components/UI/DynamicImage/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import {httpRequest} from "@/api";
import Preloader from "@/components/UI/Preloader/index.vue";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";

export default {
  name: 'ReportsSettings',
  components: {
    CustomDropdown,
    ProgressIndicator,
    Preloader,
    TableActionsTooltip,
    AppTitle,
    SvgIcon, DynamicImage,
    SimpleInfoTable,
    TheNewHeader
  },
  data() {
    return {
      data: [],
      titles: {
        'visibility': 'Visibility',
        'tracking-visibility': 'Keywords',
        'category-ranking': 'Category Ranking',
        'top-moving': 'Ranking',
        'popularity': 'Popularity',
        'monthly-aso': 'Competitors Installs',
        'competitors-category': 'Competitors Categories',
      }
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async clear(appId) {
      await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.appNotifications.APP_NOTIFICATIONS_CLEAR + '?app_id=' + appId
      );
      await this.fetchList();
    },
    getTypeTitle(type) {
      return this.titles[type];
    },
    async fetchList() {
      this.data = [];
      let result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.appNotifications.APP_NOTIFICATIONS_LIST
      );
      this.data = result.list;
    },
  },
}
</script>

<style src="./styles.scss" lang="scss"></style>
